.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.crop-controls {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    background: #000;
}
.crop-controls .crop-btn {
    background: none;
    border: none;
    color: #fff;
    font-weight: 400;
    padding: 15px 30px;
}