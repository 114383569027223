.overflow-x-auto {
    overflow-x: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-y-hidden {
    overflow-y: hidden;
}
.overflow-visible {
    overflow: visible;
}
.overflow-visible-i {
    overflow: visible!important;
}