.border-light-blue {
    border-color: $light-blue!important;
}
.border-secondary-light {
    border-color: $secondary-light;
}

.border-dotted {
    border-style: dashed!important;
}
.border-2 {
    border-width: 2px!important;
}