$button-fb-color: #4caf50d4;

.down-arrow-container {
  z-index: 999;
  bottom: 121px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  & .MuiIconButton-root {
    color: #fff;
    background-color: $button-fb-color;
    padding: 0.1rem;
    animation: bounce 1s ease infinite;
    &:hover {
      background-color: $button-fb-color;
    }
  }
}

@keyframes bounce {
  50% {
    transform: translateY(-20%);
  }
}