.help-container {
    margin: {
        left: 10px;
        right: 10px;
    }
}
.help-grid {
    margin-top: 10px;
}
.card-media {
    & iframe {
        width: 100%;
        min-height: 200px;
    }
}