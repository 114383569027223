.allGraph{
  background-color: white;
  border-radius: 10px;
  border:1px solid #dee2 ;
}
.data_container {
  line-height: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 5%;
  padding: 5%;
}
.data_container > p {
  display: flex;
  justify-content: space-between;
}

.data_container p:nth-child(1) {
  color: #2874a6;
}
.data_container p:nth-child(2) {
  color: #f69697;
}
.data_container p:nth-child(3) {
  color: #f01e2c;
}
.data_container p:nth-child(4) {
  color: #c30010;
}
.data_container p:nth-child(5) {
  color: #5ced73;
}
.data_container p:nth-child(6) {
  color: #00c04b;
}
.data_container p:nth-child(7) {
  color: #008631;
}
.data_container p:nth-child(8) {
  color: #f4d03f;
}

.pie_container {
  line-height: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 5%;
  padding: 5%;
  margin-top: 0px;
}
.pie_container > p {
  display: flex;
  justify-content: space-between;
}

.pie_container p:nth-child(1) {
  color: #85c1e9;
}
.pie_container p:nth-child(2) {
  color: #3498db;
}
.pie_container p:nth-child(3) {
  color: #2874a6;
}
.pie_container p:nth-child(4) {
  color: #5ced73;
}
.pie_container p:nth-child(5) {
  color: #00c04b;
}
.pie_container p:nth-child(6) {
  color: #008631;
}
.pie_container p:nth-child(7) {
  color: #f4d03f;
}
.no_data {
  font-size: 30px;
  font-weight: 600;
  margin: auto;
  color: green;
  text-align: center;
  display: block;
}
