.right-0 {
    right: 0 !important;
}
.right-1 {
    right: 0.25rem !important;
}
.right-2 {
    right: 0.5rem !important;
}
.right-3 {
    right: 1rem !important;
}
.right-4 {
    right: 1.5rem !important;
}
.right-5 {
    right: 3rem !important;
}
.left-50 {
    left: 50%!important;
}
.top-n15 {
    top: -15px;
}
.top-0 {
    top: 0;
}
.top-50 {
    top: 50%!important;
}
.bottom-3 {
    bottom: 1rem;
}
.z-index-9 {
    z-index: 9;
}
.z-index-99 {
    z-index: 99;
}
.z-index-999 {
    z-index: 999;
}
.zIndex991 {
    position: relative;
    z-index: 991;
}
.zIndex-1000, .z-index-1000 {
    z-index: 1000;
}
.z-index-1001 {
    z-index: 1001;
}
.z-index-1001i {
    z-index: 1001!important;
}
.z-index-1031i {
    z-index: 1031!important;
}
.zIndex-1000i {
    z-index: 1000!important;
}
.translate-middle {
    transform: translate(-50%,-50%)!important;
}