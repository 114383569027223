.customer-list-scrollbar {
    height: calc(100vh - 228px)!important;
    @media (min-width: map-get($grid-breakpoints, "md")) {
        height: calc(100vh - 170.31px)!important;
    }
}
.customer-list-milk-total-scrollbar,
.customer-list-milk-detail-scrollbar {
    height: calc(100vh - 122.31px)!important;
}
.dairy-milk-detail-scrollbar {
    height: calc(100vh - 200px)!important;
    @media (min-width: map-get($grid-breakpoints, "md")) {
        height: calc(100vh - 445.59px)!important;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        height: calc(100vh - 311.59px)!important;
    }
}
.dairy-product-list-scrollbar {
    height: calc(100vh - 177px)!important;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        height: calc(100vh - 121px)!important;
    }
}
.dairy-product-form-scrollbar {
    height: calc(100vh - 183px)!important;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        height: calc(100vh - 130px)!important;
    }
}
.dairy-product-sales-list-scrollbar {
    height: calc(100vh - 305px)!important;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        height: calc(100vh - 245px)!important;
    }
}
.dairy-product-sales-form-scrollbar {
    height: calc(100vh - 135px)!important;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        height: calc(100vh - 75px)!important;
    }
}
.dairy-billing-dairy-bill-scrollbar {
    height: calc(100vh - 175px)!important;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
        height: calc(100vh - 122px)!important;
    }
}
.dairy-billing-sellers-bill-scrollbar-false,
.dairy-billing-buyers-bill-scrollbar-false {
    height: calc(100vh - 225px)!important;
    @media (min-width: map-get($grid-breakpoints, "md")) {
        height: calc(100vh - 175px)!important;
    }
}
.dairy-billing-sellers-bill-scrollbar-true,
.dairy-billing-buyers-bill-scrollbar-true {
    height: calc(100vh - 165px)!important;
}
.dairy-billing-customer-bill-milk-total-scrollbar,
.dairy-billing-customer-bill-advance-scrollbar {
    height: 265px!important;
}
.dairy-billing-customer-bill-product-purchase-detail,
.dairy-billing-customer-bill-customer-payment-statement {
    min-height: 265px;
    height: calc(100vh - 470px)!important;
}
.advance-payment-payments-list-scrollbar {
    height: calc(100vh - 175px)!important;
}
.dairy-loan-dashboard-scrollbar,
.dairy-loan-customer-loan-history-scrollbar {
    height: calc(100vh - 125px)!important;
}

.my-milk-diary-milk-detail-list-scrollbar {
    height: calc(100vh - 162px)!important;
}
.my-milk-diary-milk-detail-total-scrollbar {
    height: calc(100vh - 114px)!important;
}

.dairy-kisan-customer-milk-total-scrollbar {
    height: calc(100vh - 340px)!important;
}

.subscription-orders-list-scrollbar {
    height: calc(100vh - 140px)!important;
}