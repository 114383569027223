.min-w-auto {
  min-width: auto!important;
}
.mh-lg-auto {
  @media (min-width: map-get($grid-breakpoints, "lg")) {
      max-height: unset!important;
  }
}
.max-w-25 {
  max-width: 25px;
}
.min-w-30 {
  min-width: 30px;
}
.max-w-30 {
  max-width: 30px;
}
.min-h-30 {
  min-height: 30px;
}
.width30px {
  width: 30px;
}
.width60 {
  width: 60px;
}
.width65, .w-65 {
  width: 65px;
}
.width70 {
width: 70px;
}
.width80 {
width: 80px;
}
.width120 {
width: 120px;
}
.width130{
width: 130px;
}
.width150{
  width: 150px;
}
.width200{
  width: 200px;
}
.width325 {
width: 325px;
}
.width340 {
width: 340px;
}
.width385 {
width: 385px;
}
.width425 {
width: 425px;
}
.width100p{
  width: 100%;
}
.width45p{
  width: 45%;
}
.width30p{
  width: 30%;
}
.width25p{
  width: 25%;
}
.width10p{
  width: 10%;
}
.width5p{
  width: 5%;
}
.width13p{
  width: 13%;
}

.min-width30i {
min-width: 30px!important;
}
.min-width65 {
min-width: 65px;
}
.min-width68 {
min-width: 65px;
}
.min-width95 {
min-width: 95px;
}
.min-width105 {
min-width: 105px;
}
.min-width130{
min-width: 130px;
}
.min-width340 {
min-width: 340px;
}
.min-width-590 {
min-width: 590px;
}

.max-width-20 {
max-width: 20px;
}
.max-width-25 {
max-width: 25px;
}
.max-width-30 {
max-width: 30px;
}
.max-width-35 {
max-width: 35px;
}
.max-width-200 {
max-width: 200px;
}
.max-width-300 {
max-width: 300px;
}
.max-width-400 {
max-width: 400px;
}
.max-width-500 {
max-width: 500px;
}
.max-vh-65 {
max-height: 65vh;
}
.width20 {
width: 20px;
}