@charset 'UTF-8';

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: url('./Poppins/Poppins-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url('./Poppins/Poppins-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./Poppins/Poppins-Bold.ttf') format('ttf');
}