
.subscription_box_bg {
    background-image: linear-gradient(39deg,#6e78f7cc -28%,#278e3c 102%);
    // height: 260px;
    // margin-bottom: 90px;
}
.plan_and_subs_validity_box {
    bottom: -5px;
}
.offer-card {
    max-width: 172px;
    height: 207px;
}
.offer-card .MuiCard-root {
    background-color: #4EC882;
    border: 1px solid #17b45a;
    color: #fff;
}
.offer-footer {
    background-color: #17B45A;
    padding: 0.5rem 0px;
}
.offer-popup-circle {
    display: inline-block;
    width: 116px;
    height: 116px;
    padding: 25px;
    background-color: #176034;
    color: #fff;
    font-size: 2rem;
    line-height: 34px;
    transform: rotate(15deg);
    position: relative;
    overflow: hidden;
}
.offer-popup-circle::before {
    content: " ";
    width: 80px;
    height: 33px;
    background: #ffffff;
    position: absolute;
    top: 0px;
    opacity: 0.3;
    transform: rotate(9deg);
    border-radius: 100%;
}
.offer-popup-circle::after {
    content: " ";
    width: 111px;
    height: 41px;
    background: #176034;
    position: absolute;
    top: 11px;
    left: 5px;
    transform: rotate(9deg);
    border-radius: 100%;
}
span.offer-bg-circle-1 {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 183px;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    background-color: rgb(255 255 255 / 3%);
    transform: translate(-50%, -50%);
}
span.offer-bg-circle-2 {
    display: inline-block;
    position: absolute;
    width: 75%;
    height: 137px;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    background-color: rgb(255 255 255 / 7%);
    transform: translate(-50%, -50%);
}
span.offer-bg-circle-3 {
    display: inline-block;
    position: absolute;
    width: 50%;
    height: 92px;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    background-color: rgb(255 255 255 / 10%);
    transform: translate(-50%, -50%);
}