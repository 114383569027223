 .iconStyle {
     width: 60px;
 }

 .iconArrowStyle {
     width: 15px;
     transform: scaleX(-1);
 }

 .iconMirrorArrowStyle {
    width: 15px;
    transform: rotate(-9deg);
    margin-left: 12px;
 }

 .coinImageStyle {
     max-height: 120px;
 }

 .cardStyle {
     background: #00ffb808 !important;
 }

 .iconStyle2 {
     background: white;
     width: 45px;
     height: 45px;
     padding: 9px 13px;
     border-radius: 50%;
 }

 .rewardTextStyle {
     font-size: 12px;
 }

 .totalRewardBoxStyle {
     margin-top: -20px;
 }

 .totalRewardStyle {
     color: #80808085;
     font-size: 12px;
 }

 .rightArrowStyle {
     background: #8080802e;
     border-radius: 50%;
     color: blue;
     font-size: 11px;
     padding: 9px;
 }

 .popup-paragraph{
    font-size: 21px;
 }
 .social-text{
    font-size: 16px!important;
    font-weight: bold!important;
 }

 .share-link:hover{
    color: blue;
    cursor: pointer;
 }

 .share-btn-link{
    color: #0076ff;
 }

 .share-btn-link:hover{
    border-bottom: 1px solid #0076ff;
    cursor: pointer;
 }



 @media print {
    tr.vendorListHeading {
        background-color: #1a4567 !important;
        -webkit-print-color-adjust: exact; 
    }
}

@media print {
    .vendorListHeading th {
        color: white !important;
    }
}

.file-export-boder{
    border: 2px dashed #5cb848;
    padding: 5px;
    width: 25;
    border-radius: 5px;
}


