.swal2-popup {
    &.confirm-popup {
        padding: 0;
    }
}

.swal2-icon {
    &.swal-popup-icon-small {
        width: 4rem;
        height: 4rem;

        & .swal2-icon-content {
            font-size: 2.75rem;
        }
    }
}
.swal2-title {
    &.payment-success-title {
        font-size: 1.5rem;
    }
    &.confirm-popup-title {
        font-size: 1.25rem;
        padding: 1rem 1.5rem;
        text-align: left;
    }
}

.swal2-html-container {
    &.confirm-popup-html-container {
        text-align: left;
        font-size: 1rem;
        padding: 0.5em 1.5em;
        margin: 0;
    }
}

.swal2-actions {
    &.confirm-popup-actions {
        justify-content: flex-end;
        padding: 0.5rem;
        margin: initial;
        & button {
            font-size: 1rem;
        }
    }
}

.swal2-container {
    &.confirm-popup-container {
        z-index: 1301;
    }
}