.plans-box {
    display: flex;
    width: 90%;
    margin: 0 auto;
}
.plan {
    flex: 0 0 25%;
    height: 100px;
    background-color: #E46119;
    border: 1px solid #626262;
    margin: 3px;
}