.font-family-default {
    font-family: "Poppins";
}
.font-size-1_2 {
    font-size: 1.2rem;
}
.text-green-light {
    color: $green-light;
}
.text-blue-light {
    color: $light-blue;
}
.font-weight-bold-500 {
    font-weight: 500;
}
.lheight-1 {
    line-height: 1;
}
.lheight-1i {
    line-height: 1!important;
}
.lheight-1-5 {
    line-height: 1.5;
}
.lheight-24 {
    line-height: 24px;
}
.lheight-28 {
    line-height: 28px;
}
.lheight-30 {
    line-height: 30px;
}
.lheight-45 {
    line-height: 45px;
}
.lheight-49 {
    line-height: 49px;
}

.font-size-20 {
    font-size: 20px;
}
.font-size-85r {
    font-size: 0.85rem;
}
.font-size-1_8r {
    font-size: 1.8rem!important;
}
.font-size-1_5r {
    font-size: 1.5rem;
}
.font-size-1_2ri {
    font-size: 1.2rem!important;
}