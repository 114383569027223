.subscription-upsale-modal .modal_content {
    border-radius: 0;
}
.subscription-upsale-modal .offer-circle-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1em;
}
.subscription-upsale-modal .offer-deal-text img{
    width: 20px;
    vertical-align: baseline;
}
.subscription-upsale-modal .offer-circle {
    font-size: 3rem;
    font-weight: 600;
}
.subscription-upsale-modal .coupon-code span {
    font-size: 2rem;
    font-weight: bold;
    padding: 0.2em 0.5em;
    border: 2px dashed #000;
    display: inline-block;
}
.subscription-upsale-modal .upsale-timer > div {
    margin: 0 auto;
}