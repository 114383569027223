.coupon-paper {
    padding: 8px 8px 8px 0px;
    display: flex;
    align-items: center;
}
.coupon-input {
    margin-left: 10px;
    flex: 1;
}
.coupon-clear-btn, .coupon-apply-btn {
    padding: 5;
    margin-left: 10px;
    background-color: #64c568;
}
.coupon-apply-btn {
    font-weight: 600;
    color: #fff;
}